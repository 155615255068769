import { AreTypesAssignable, GetTypeProps, type Type } from "../Types/Type"
import { Reconstruct } from "./Reconstruct"
import { IsUuidType, Uuid } from "./Primitives/Uuid"

/**
 * Duplicates an object, replacing all internal Uuid's with new Uuid's, and
 * remapping any internal references to the new Uuid's.
 *
 * This is useful for implementing the Duplicate feature in Studio, among other
 * things.
 */
export function Duplicate<T>(item: T, itemType: Type): T {
    const remappings = new Map<Uuid, Uuid>()

    const isNonReferenceUuid = (type: Type) =>
        IsUuidType(type) && !(typeof type === "object" && type.reference)

    // Replace all Uuid's that are not references with new Uuid's
    const newItem = Reconstruct(
        item,
        itemType,
        (value, type) => isNonReferenceUuid(type),
        (value, type) => {
            const newValue = Uuid()
            // Keep a mapping of old Uuid's to new Uuid's
            remappings.set(value as any as Uuid, newValue)
            return newValue
        }
    )

    const isReference = (type: Type) =>
        IsUuidType(type) && typeof type === "object" && !!type.reference

    // Replace all Uuid's that are references with the new Uuid's, if they exist
    // in the remappings table
    const newItem2 = Reconstruct(
        newItem,
        itemType,
        (value, type) => isReference(type),
        (value, type) =>
            // Use the remapped value if it exists
            (remappings.get(value as any as Uuid) as any) ??
            // Otherwise, return the original value
            value
    ) as T

    // Clear all @unique props, if possible
    let newItem3: any = Reconstruct(
        newItem2,
        itemType,
        (value, type, prop) => !!prop?.tags?.unique,
        (value, type) => {
            if (!AreTypesAssignable(type, "undefined")) {
                throw new Error("Cannot clear @unique properties that are not optional")
            }
            return undefined
        }
    ) as T

    const props = GetTypeProps(itemType)
    const titleProp = props.find((p) => p.tags?.title)?.name
    if (titleProp) {
        newItem3[titleProp] = newItem3[titleProp] + " (Copy)"
    } else {
        // If no @title prop is found, append " (Copy)" to an
        // appropriate-looking string value
        let foundString = false
        newItem3 = Reconstruct(
            newItem3,
            itemType,
            (value, type) => {
                const isString = !foundString && typeof value === "string" && type === "string"
                if (isString) foundString = true
                return isString
            },
            (value, type) => value + " (Copy)"
        ) as T
    }

    return newItem3
}
